module.exports = {
  Home: {
    path: "/",
    component: "src/pages/HomeRedirect/index.js"
  },
  Proposal: {
    path: "/proposta",
    component: "src/pages/Proposal/index.js",
    context: {
      currentFlow: "hire"
    }
  },
  HomePersonnalite: {
    path: "/personnalite",
    component: "src/pages/HomeRedirect/index.js"
  },
  ProposalPersonnalite: {
    path: "/personnalite/proposta",
    component: "src/pages/Proposal/index.js",
    context: {
      currentFlow: "personnalite"
    }
  },
  Skipper: {
    path: `/skipper`,
    component: `src/pages/Skipper/index.js`
  },
  404: {
    path: "/404/",
    component: "src/pages/404/index.js"
  }
}
